/* eslint-disable no-unused-vars */
import { motion } from "framer-motion";
import { graphql, Link, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import * as React from "react";
import { BannerStyles } from "../components/Banner/bannerStyles";
import Button from "../components/Button/button";
import SEO from "../components/seo";

const NotFoundPage = () => {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "pexels-pineapple-supply-co-139259.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000, quality: 90) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`);

	const variants = {
		visible: { opacity: 1, y: 0 },
		hidden: { opacity: 0, y: 20 },
	};
	return (
		<React.Fragment>
			<SEO title="Page not Found" />
			<BannerStyles>
				<BackgroundImage
					Tag="section"
					className="hero-image"
					fluid={data.file.childImageSharp.fluid}
				>
					<div className="hero-content">
						<motion.h1
							initial="hidden"
							animate="visible"
							variants={variants}
							transition={{ ease: "easeOut", duration: 0.8, delay: 0 }}
						>
							Oops, we can't find that <span>page</span>
						</motion.h1>
						<Link to="/">
							<span className="sr-only">Go Home</span>
							<Button cta="Go Home" label="Go Home" />
						</Link>
					</div>
				</BackgroundImage>
			</BannerStyles>
		</React.Fragment>
	);
};

export default NotFoundPage;
